import { useState } from 'react';
import { StyledTable, Thead, Tr, Th, Td, Tbody } from '../Table.styles';
import { TableInput } from '../TableInput/TableInput';
import {
  DateRange,
  ModalHeader,
  TableContainer,
  ButtonContainer,
  Heading,
  CloseButton,
  RemoveButton,
  ReimbursementContainer,
  ReimbursementsInstructions,
  InputContainer,
  HorizontalRule,
  Input,
  InputLabel,
  TableBar,
  SaveStatusContainer,
  SavingMessage,
  SavedMessage,
  StyledSavingIcon,
  StyledSaveIcon,
  CompensationTd,
  InputWrapper,
  DollarSign,
} from './RunPayrollTables.styles';
import { PayrollItem } from 'lib/fetchPayrollItemsList';
import { PayrollStatus, EarningType } from 'lib/fetchProviderPayroll';
import ReactModal from 'react-modal';
import { updatePayrollItem, UpdatePayrollItemInput, Earning } from 'lib/updatePayrollItem';
import { toast } from 'react-toastify';
import { ActionButton } from 'components/ActionButton/actionButton';
import { currencyFormatter } from 'lib/utils/currencyFormatter';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { ReactComponent as RemoveIcon } from '@src/assets/icons/close-circle.svg';

type BonusCommissionFields = {
  bonus: number;
  commission: number;
};

type AdditionalEarningsTableProps = {
  items: PayrollItem[];
  periodStart: string;
  periodEnd: string;
  status: PayrollStatus | null;
  refreshPayrollData: () => void;
};

type ReimbursementState = {
  amount: number;
  amountInput?: string;
  description: string;
  code: string;
};

export const AdditionalEarningsTable = ({
  items: initialItems,
  periodStart,
  periodEnd,
  status,
}: AdditionalEarningsTableProps) => {
  const [items, setItems] = useState<PayrollItem[]>(initialItems);
  const [inputFields, setInputFields] = useState<Map<string, BonusCommissionFields>>(
    new Map(
      initialItems.map((item) => [
        item.employee.id,
        {
          bonus:
            item.providerAttributes.earnings.find((earning) => earning.type === 'bonus')?.amount ||
            0,
          commission:
            item.providerAttributes.earnings.find((earning) => earning.type === 'commission')
              ?.amount || 0,
        },
      ])
    )
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<PayrollItem['employee'] | null>(null);
  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [currentReimbursements, setCurrentReimbursements] = useState<ReimbursementState[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const utcDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return utcDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const calculateGrossPay = (item: PayrollItem): string => {
    const fields = inputFields.get(item.employee.id) || { bonus: 0, commission: 0 };
    const bonus = parseFloat(fields.bonus.toString()) || 0;
    const commission = parseFloat(fields.commission.toString()) || 0;

    let regularPay = 0,
      overtimePay = 0,
      ptoPay = 0,
      sickPay = 0;
    let hourlyRate = item.employee.compensation.amount;

    if (item.employee.compensation.type === 'SALARIED') {
      hourlyRate = item.employee.compensation.amount / (40 * 52);
    }

    item.providerAttributes.earnings.forEach((earning) => {
      const hours = earning.hours !== null ? parseFloat(earning.hours.toString()) : 0;
      switch (earning.type) {
        case 'hourly':
        case 'salaried':
          regularPay += hours * hourlyRate;
          break;
        case 'overtime':
          overtimePay += hours * hourlyRate * 1.5;
          break;
        case 'pto':
          ptoPay += hours * hourlyRate;
          break;
        case 'sick':
          sickPay += hours * hourlyRate;
          break;
        default:
          break;
      }
    });

    const reimbursementTotal = item.providerAttributes.reimbursements.reduce(
      (acc, reimbursement) => acc + parseFloat(reimbursement.amount.toString()),
      0
    );

    const totalEarnings =
      regularPay + overtimePay + ptoPay + sickPay + bonus + commission + reimbursementTotal;
    return currencyFormatter(totalEarnings);
  };

  const updateEarnings = async (employeeId: string) => {
    setIsSaving(true);
    setIsSaved(false);

    const fields = inputFields.get(employeeId);
    if (!fields) return;

    const payrollItem = items.find((item) => item.employee.id === employeeId);
    if (payrollItem) {
      const existingEarnings = payrollItem.providerAttributes.earnings
        .filter(
          (earning) => earning.type !== EarningType.Bonus && earning.type !== EarningType.Commission
        )
        .map((earning) => ({
          type: earning.type as EarningType,
          amount: earning.amount.toString(),
          description: earning.description || null,
          hours: earning.hours ? earning.hours.toString() : null,
        }));

      const bonusEarning: Earning[] =
        fields.bonus > 0
          ? [
              {
                type: EarningType.Bonus,
                amount: fields.bonus.toString(),
                description: 'Bonus',
                hours: null,
              },
            ]
          : [];

      const commissionEarning: Earning[] =
        fields.commission > 0
          ? [
              {
                type: EarningType.Commission,
                amount: fields.commission.toString(),
                description: 'Commission',
                hours: null,
              },
            ]
          : [];

      const earningsUpdate: Earning[] = [
        ...existingEarnings,
        ...bonusEarning,
        ...commissionEarning,
      ];

      try {
        await updatePayrollItem({
          id: payrollItem.id,
          earnings: earningsUpdate,
        });
        setIsSaving(false);
        setIsSaved(true);
      } catch (error) {
        setIsSaving(false);
        toast.error(`Failed to update payroll: ${error}`);
      }
    } else {
      toast.error('Payroll Item ID not found');
    }
  };

  const handleInputChange = (
    employeeId: string,
    field: keyof BonusCommissionFields,
    value: string
  ): void => {
    const numericValue = parseFloat(value) || 0;
    setInputFields((prev) => {
      const currentFields = prev.get(employeeId) || { bonus: 0, commission: 0 };
      const updatedFields = { ...currentFields, [field]: numericValue };
      return new Map(prev).set(employeeId, updatedFields);
    });
  };

  const handleOpenModal = (employee: PayrollItem['employee']): void => {
    setSelectedEmployee(employee);
    setCurrentReimbursements(
      items
        .find((item) => item.employee.id === employee.id)
        ?.providerAttributes.reimbursements.map((r) => ({
          description: r.description,
          amount: parseFloat(r.amount.toString()),
          code: r.code,
        })) || []
    );
    setModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
    setSelectedEmployee(null);
    setDescription('');
    setAmount('');
    setCurrentReimbursements([]);
  };

  const handleAddReimbursement = (): void => {
    setCurrentReimbursements((prev) => [...prev, { description: '', amount: 0, code: 'new' }]);
  };

  const handleRemoveReimbursement = (index: number): void => {
    setCurrentReimbursements((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = (): void => {
    if (selectedEmployee) {
      const payrollItemId = items.find((item) => item.employee.id === selectedEmployee.id)?.id;
      if (payrollItemId) {
        const filteredReimbursements = currentReimbursements.filter(
          (r) => r.description.trim() !== '' && r.amount > 0
        );

        const updatePayload: UpdatePayrollItemInput = {
          id: payrollItemId,
          reimbursements: filteredReimbursements.map((r) => ({
            description: r.description,
            amount: r.amount.toString(),
            code: r.code,
          })),
        };

        updatePayrollItem(updatePayload)
          .then(() => {
            toast.success('Reimbursements updated successfully');
            setItems((prevItems) =>
              prevItems.map((item) =>
                item.employee.id === selectedEmployee.id
                  ? {
                      ...item,
                      providerAttributes: {
                        ...item.providerAttributes,
                        reimbursements: filteredReimbursements,
                      },
                    }
                  : item
              )
            );
            handleCloseModal();
          })
          .catch((error) => {
            toast.error(`Failed to update reimbursements: ${error}`);
          });
      } else {
        toast.error('Payroll Item ID not found');
      }
    }
  };

  let hasInsertedHourlyLabel = false;
  let hasInsertedSalariedLabel = false;

  return (
    <>
      <TableBar>
        <DateRange>{`Pay Period: ${formatDate(periodStart)} - ${formatDate(periodEnd)}`}</DateRange>
        {isSaving && (
          <SaveStatusContainer>
            <SavingMessage>
              <StyledSavingIcon />
              Saving...
            </SavingMessage>
          </SaveStatusContainer>
        )}
        {isSaved && (
          <SaveStatusContainer>
            <SavedMessage>
              <StyledSaveIcon />
              Saved
            </SavedMessage>
          </SaveStatusContainer>
        )}
      </TableBar>
      <StyledTable>
        <Thead>
          <Tr>
            <Th>Employee Name</Th>
            <Th>Bonus</Th>
            <Th>Commission</Th>
            <Th style={{ textAlign: 'center' }}>Reimbursements</Th>
            <Th>Gross Pay</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, index) => {
            const fields = inputFields.get(item.employee.id) || {
              bonus: 0,
              commission: 0,
            };
            const grossPay = calculateGrossPay(item);
            const isDisabled = status !== 'draft';

            // Insert a row for "Hourly" if we haven't already
            const isHourly = item.employee.compensation.type === 'HOURLY';
            if (isHourly && !hasInsertedHourlyLabel) {
              hasInsertedHourlyLabel = true;
              return (
                <>
                  <Tr key={`hourly-label-${index}`}>
                    <CompensationTd colSpan={5}>Hourly</CompensationTd>
                  </Tr>
                  <Tr key={item.employee.id}>
                    <Td>{item.employee.name}</Td>
                    <Td>
                      <TableInput
                        format="currency"
                        value={fields.bonus}
                        onChange={(newValue) =>
                          handleInputChange(item.employee.id, 'bonus', newValue)
                        }
                        onBlur={() => updateEarnings(item.employee.id)}
                        disabled={isDisabled}
                      />
                    </Td>
                    <Td>
                      <TableInput
                        format="currency"
                        value={fields.commission}
                        onChange={(newValue) =>
                          handleInputChange(item.employee.id, 'commission', newValue)
                        }
                        onBlur={() => updateEarnings(item.employee.id)}
                        disabled={isDisabled}
                      />
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {item.providerAttributes.reimbursements.length > 0 ? (
                        <>
                          {item.providerAttributes.reimbursements.map(
                            (reimbursement, reimbursementIndex) => (
                              <div key={reimbursementIndex}>
                                <span>{currencyFormatter(reimbursement.amount)}</span>
                              </div>
                            )
                          )}
                          <HorizontalRule />
                          {status === 'draft' && (
                            <ActionButton
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpenModal(item.employee);
                              }}
                              size="medium"
                              variant="text"
                              title="View/Edit"
                              hidden={false}
                            />
                          )}
                        </>
                      ) : (
                        status === 'draft' && (
                          <ActionButton
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenModal(item.employee);
                            }}
                            size="medium"
                            variant="text"
                            title="Add"
                            hidden={false}
                          />
                        )
                      )}
                    </Td>
                    <Td>{grossPay}</Td>
                  </Tr>
                </>
              );
            }

            // Insert a row for "Salaried" if we haven't already
            const isSalaried = item.employee.compensation.type === 'SALARIED';
            if (isSalaried && !hasInsertedSalariedLabel) {
              hasInsertedSalariedLabel = true;
              return (
                <>
                  <Tr key={`salaried-label-${index}`}>
                    <CompensationTd colSpan={5}>Salaried</CompensationTd>
                  </Tr>
                  <Tr key={item.employee.id}>
                    <Td>{item.employee.name}</Td>
                    <Td>
                      <TableInput
                        format="currency"
                        value={fields.bonus}
                        onChange={(newValue) =>
                          handleInputChange(item.employee.id, 'bonus', newValue)
                        }
                        onBlur={() => updateEarnings(item.employee.id)}
                        disabled={isDisabled}
                      />
                    </Td>
                    <Td>
                      <TableInput
                        format="currency"
                        value={fields.commission}
                        onChange={(newValue) =>
                          handleInputChange(item.employee.id, 'commission', newValue)
                        }
                        onBlur={() => updateEarnings(item.employee.id)}
                        disabled={isDisabled}
                      />
                    </Td>
                    <Td style={{ textAlign: 'center' }}>
                      {item.providerAttributes.reimbursements.length > 0 ? (
                        <>
                          {item.providerAttributes.reimbursements.map(
                            (reimbursement, reimbursementIndex) => (
                              <div key={reimbursementIndex}>
                                <span>{currencyFormatter(reimbursement.amount)}</span>
                              </div>
                            )
                          )}
                          <HorizontalRule />
                          {status === 'draft' && (
                            <ActionButton
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpenModal(item.employee);
                              }}
                              size="medium"
                              variant="text"
                              title="View/Edit"
                              hidden={false}
                            />
                          )}
                        </>
                      ) : (
                        status === 'draft' && (
                          <ActionButton
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenModal(item.employee);
                            }}
                            size="medium"
                            variant="text"
                            title="Add"
                            hidden={false}
                          />
                        )
                      )}
                    </Td>
                    <Td>{grossPay}</Td>
                  </Tr>
                </>
              );
            }

            return (
              <Tr key={item.employee.id}>
                <Td>{item.employee.name}</Td>
                <Td>
                  <TableInput
                    format="currency"
                    value={fields.bonus}
                    onChange={(newValue) => handleInputChange(item.employee.id, 'bonus', newValue)}
                    onBlur={() => updateEarnings(item.employee.id)}
                    disabled={isDisabled}
                  />
                </Td>
                <Td>
                  <TableInput
                    format="currency"
                    value={fields.commission}
                    onChange={(newValue) =>
                      handleInputChange(item.employee.id, 'commission', newValue)
                    }
                    onBlur={() => updateEarnings(item.employee.id)}
                    disabled={isDisabled}
                  />
                </Td>
                <Td style={{ textAlign: 'center' }}>
                  {item.providerAttributes.reimbursements.length > 0 ? (
                    <>
                      {item.providerAttributes.reimbursements.map(
                        (reimbursement, reimbursementIndex) => (
                          <div key={reimbursementIndex}>
                            <span>{currencyFormatter(reimbursement.amount)}</span>
                          </div>
                        )
                      )}
                      <HorizontalRule />
                      {status === 'draft' && (
                        <ActionButton
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpenModal(item.employee);
                          }}
                          size="medium"
                          variant="text"
                          title="View/Edit"
                          hidden={false}
                        />
                      )}
                    </>
                  ) : (
                    status === 'draft' && (
                      <ActionButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenModal(item.employee);
                        }}
                        size="medium"
                        variant="text"
                        title="Add"
                        hidden={false}
                      />
                    )
                  )}
                </Td>
                <Td>{grossPay}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </StyledTable>

      {selectedEmployee && (
        <ReactModal
          style={{
            overlay: {
              position: 'fixed',
              inset: '0px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 11,
            },
            content: {
              top: '45%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              borderRadius: '16px',
              overflow: 'visible',
              padding: '0px',
            },
          }}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        >
          <ModalHeader>
            <Heading>Reimbursements</Heading>
          </ModalHeader>
          <TableContainer>
            <ReimbursementsInstructions>
              All reimbursements are non-recurring and only apply to this Payroll.{' '}
            </ReimbursementsInstructions>
            {currentReimbursements.map((reimbursement, index) => (
              <ReimbursementContainer key={index}>
                <InputContainer>
                  <InputLabel>Description</InputLabel>
                  <Input
                    type="text"
                    value={reimbursement.description}
                    onChange={(e) =>
                      setCurrentReimbursements((prev) =>
                        prev.map((item, i) =>
                          i === index ? { ...item, description: e.target.value } : item
                        )
                      )
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <InputLabel>Amount</InputLabel>
                  <InputWrapper>
                    <DollarSign>$</DollarSign>
                    <Input
                      type="text"
                      value={
                        reimbursement.amountInput !== undefined
                          ? reimbursement.amountInput
                          : reimbursement.amount.toFixed(2)
                      }
                      isCurrency
                      onChange={(e) => {
                        const value = e.target.value;

                        if (/^-?\d*(\.\d{0,2})?$/.test(value)) {
                          setCurrentReimbursements((prev) =>
                            prev.map((item, i) =>
                              i === index ? { ...item, amountInput: value } : item
                            )
                          );
                        }
                      }}
                      onBlur={() => {
                        const formattedAmount = parseFloat(
                          reimbursement.amountInput ?? '0'
                        ).toFixed(2);

                        setCurrentReimbursements((prev) =>
                          prev.map((item, i) =>
                            i === index
                              ? {
                                  ...item,
                                  amount: parseFloat(formattedAmount),
                                  amountInput: undefined,
                                }
                              : item
                          )
                        );
                      }}
                    />
                  </InputWrapper>
                </InputContainer>

                <RemoveButton
                  icon={<RemoveIcon />}
                  onClick={() => handleRemoveReimbursement(index)}
                />
              </ReimbursementContainer>
            ))}
            <ActionButton
              onClick={handleAddReimbursement}
              size="medium"
              title="Add"
              variant="text"
              hidden={false}
            />
          </TableContainer>
          <ButtonContainer>
            <ActionButton onClick={handleSave} size="medium" title="Save" hidden={false} />
          </ButtonContainer>
          <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
        </ReactModal>
      )}
    </>
  );
};
