import React, { useState, useEffect } from 'react';
import { Input, InputWrapper } from './TableInput.styles';

type TableInputProps = {
  value: number | string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  format?: 'currency' | 'hours';
};

export const TableInput = ({
  value,
  onChange,
  onBlur,
  placeholder = '',
  disabled = false,
  format = 'hours',
}: TableInputProps) => {
  const [inputValue, setInputValue] = useState<string>(
    format === 'currency' ? `$${parseFloat(value as string).toFixed(2)}` : value.toString()
  );

  useEffect(() => {
    if (format === 'currency') {
      const numericValue = parseFloat(value as string) || 0;
      setInputValue(`$${numericValue.toFixed(2)}`);
    } else {
      setInputValue(value.toString());
    }
  }, [value, format]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (format === 'currency') {
      const validCurrency = newValue.replace(/[^0-9.]/g, '');
      const parts = validCurrency.split('.');

      if (parts.length > 1 && parts[1].length > 2) {
        return;
      }

      setInputValue(`$${validCurrency}`);
    } else {
      const validHours = newValue.replace(/[^0-9.]/g, '');
      setInputValue(validHours);
    }
  };

  const handleInputBlur = () => {
    if (format === 'currency') {
      const numericValue = parseFloat(inputValue.replace(/[^0-9.-]/g, '')) || 0;
      setInputValue(`$${numericValue.toFixed(2)}`);
      onChange(numericValue.toFixed(2));
    } else {
      const numericValue = parseFloat(inputValue) || 0;
      setInputValue(numericValue.toString());
      onChange(numericValue.toString());
    }

    onBlur?.();
  };

  return (
    <InputWrapper>
      <Input
        type="text"
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        disabled={disabled}
      />
    </InputWrapper>
  );
};
