import { cloneElement, useState, useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import { useNavigate } from 'react-router-dom';
import { Section, PageContainer } from 'components/SharedComponents/Containers.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import Tooltip from 'components/Tooltip/tooltip';
import ReactModal from 'react-modal';
import { PayrollsTable } from 'components/Table/PayrollsTable/PayrollsTable';
import { fetchProviderEmployeesList } from 'lib/fetchProviderEmployeesList';
import { selectCurrentCompany } from 'features/company/slice';
import { Company } from 'features/company/types';
import { SectionHeading, Container, PayrollContainer, ActionBar, Link } from './Dashboard.styles';
import { Timeline } from 'components/Timeline/Timeline';
import { OffCyclePayrollForm } from 'components/OffCyclyePayrollForm/OffCyclePayrollForm';
import { OffCyclePayrollModal } from 'components/OffCyclyePayrollForm/OffCyclePayrollModal';
import { fetchCompanyNextPayday, Payday } from 'lib/fetchCompanyNextPayday';
import { fetchCompanyPayrolls, PayrollTypes, PayrollStatus } from 'lib/fetchCompanyPayrolls';
import { OffCyclePayrollFormData } from 'components/OffCyclyePayrollForm/types';

export const Dashboard = () => {
  const company: Company | null = useAppSelector(selectCurrentCompany);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [hasEmployees, setHasEmployees] = useState(true);
  const [payday, setPayday] = useState<Payday | null>(null);
  const [PayrollProviderId, setPayrollProviderId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [buttonTitle, setButtonTitle] = useState('Run Payroll');
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<OffCyclePayrollFormData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const employeesList = await fetchProviderEmployeesList();
      setHasEmployees(employeesList.employees.length > 0);

      const nextPayday = await fetchCompanyNextPayday();
      setPayday(nextPayday);

      if (nextPayday) {
        const { payrolls } = await fetchCompanyPayrolls(undefined, {
          type: PayrollTypes.Regular,
          paydayBefore: nextPayday.payday,
          paydayAfter: nextPayday.payday,
        });

        if (payrolls.length > 0) {
          setPayrollProviderId(payrolls[0].providerId);
          setButtonTitle(
            payrolls[0].status === PayrollStatus.Draft ? 'Continue Payroll' : 'View Payroll'
          );
        } else {
          setPayrollProviderId(null);
          setButtonTitle('Run Payroll');
        }
      }

      setLoading(false);
    };

    fetchData();
  }, [refresh]);

  const closeModal = () => {
    setModalIsOpen(false);
    setStep(1);
    setRefresh(!refresh);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleFormSubmit = (data: OffCyclePayrollFormData) => {
    setFormData(data);
    setStep(2);
  };

  const renderModalContent = () => {
    if (step === 1) {
      return <OffCyclePayrollForm onSubmit={handleFormSubmit} onRequestClose={closeModal} />;
    } else if (step === 2 && formData) {
      return (
        <OffCyclePayrollModal
          isOpen={modalIsOpen}
          formData={formData}
          onRequestClose={closeModal}
        />
      );
    }
  };

  const renderRegularPayrollButton = () => {
    const button = (
      <ActionButton
        onClick={() =>
          navigate('/payroll/run-payroll/hours', {
            state: { PayrollProviderId, payday },
          })
        }
        size="medium"
        title={buttonTitle}
        hidden={false}
        disabled={!hasEmployees}
      />
    );

    if (process.env.NODE_ENV === 'production' && company && !company.approvedForPaymentProcessing) {
      return (
        <Tooltip
          content="Your company is not approved for payment processing. Please contact support."
          direction="left"
        >
          {cloneElement(button, { disabled: true })}
        </Tooltip>
      );
    }

    if (!hasEmployees) {
      return (
        <Tooltip
          content="You must add at least 1 employee before you can Run Payroll."
          direction="left"
        >
          {button}
        </Tooltip>
      );
    }

    return button;
  };

  const renderOffCyclePayrollButton = () => {
    return (
      <ActionButton
        onClick={openModal}
        size="medium"
        title="Run Off-Cycle Payroll"
        hidden={false}
        variant="outline"
        disabled={!hasEmployees}
      />
    );
  };

  return (
    <PageContainer>
      <ActionBar>
        {renderOffCyclePayrollButton()}
        {renderRegularPayrollButton()}
      </ActionBar>
      <Container>
        <Timeline payday={payday} />
        <Section halfWidth>
          <SectionHeading>Payroll Drafts</SectionHeading>
          <Link onClick={() => navigate('/payroll/drafts')}>View Payroll Drafts</Link>
        </Section>
      </Container>
      <PayrollContainer>
        <Section>
          <SectionHeading>Recent Payrolls</SectionHeading>
          <PayrollsTable refresh={refresh} pageSize={3} disablePagination={true} />
        </Section>
      </PayrollContainer>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 11,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-40%, -50%)',
            borderRadius: '16px',
            overflow: 'visible',
            width: step === 1 ? '500px' : '800px',
            padding: '0px',
          },
        }}
      >
        {renderModalContent()}
      </ReactModal>
    </PageContainer>
  );
};
